import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import React, { useEffect, useState } from "react";
import { isIE } from "react-device-detect";
import BrowserNotSupported from "../components/BrowserNotSupported";
import { A } from "../components/common/A";
import { BlockContentRenderer } from "../components/common/BlockContentRenderer";
import { Box } from "../components/common/Box";
import { Grid } from "../components/common/Grid";
import { H1 } from "../components/common/Heading";
import { InnerGrid } from "../components/common/InnerGrid";
import { Text } from "../components/common/Text";
import { LocaleLinks } from "../components/CurrentLocale";
import { Layout } from "../components/Layout";
import { SectionRenderer } from "../components/sections/SectionRenderer";
import { Seo } from "../components/Seo";
import { TalksProvider } from "../components/TalksProvider";
import { useThemeContext } from "../components/ThemeChanger";
import { useIsMobile } from "../hooks/useIsMobile";
import { grid } from "../theme/theme";
import { extractWithLocale } from "../utils/extractWithLocale";
import { getFixedGifSrc } from "../utils/getFixedGifSrc";
import { isNotNull } from "../utils/isNotNull";
import { PageProps } from "../utils/PageProps";
import { combineFullSections } from "../utils/rawSectionHelpers";
import { PersonQuery, Sections } from "../__generated__/graphql";

const Person: React.FC<PageProps<PersonQuery>> = ({ data, pageContext, location }) => {
  const [notSupportedBrowser, setNotSupportedBrowser] = useState(false);
  const themeCtx = useThemeContext();
  const isMobile = useIsMobile();
  useEffect(() => {
    setNotSupportedBrowser(isIE);
    themeCtx.setTheme('light');
  }, []);

  const person = data.person!;

  if (!person) return null;

  const slugs = [
    { locale: 'sv', url: person.person?.slug?.current || '' },
    { locale: 'en', url: person.person?.slug?.current || '' },
    { locale: 'dl', url: person.person?.slug?.current || '' },
  ] as LocaleLinks;
  const sections = person.sections?.filter(isNotNull) ?? [];
  const rawSections = person._rawSections?.filter(isNotNull) ?? [];
  const fullSections = combineFullSections(sections, rawSections);

  const seoTitle = person.seo && extractWithLocale(person.seo.title, pageContext.locale);
  const seoDescription = person.seo && extractWithLocale(person.seo.description, pageContext.locale);
  const seoImage = person.seo?.seoImage?.asset?.gatsbyImageData?.src;

  const firstName = person.person?.firstName || '';
  const lastName = person.person?.lastName || '';
  const name = `${firstName} ${lastName}`;
  const title = person.person?.title || '';
  const body = extractWithLocale(person.person?.body, pageContext.locale) ?? '';
  const secondaryBody = extractWithLocale(person.person?.secondaryBody, pageContext.locale) ?? '';
  const email = person.person?.email || '';
  const phone = person.person?.phone || '';

  const fluidImage = getFixedGifSrc(person.person?.image?.asset?.gatsbyImageData);

  return notSupportedBrowser ? (
    <Layout
      locale={pageContext.locale}
      alternativeLinks={slugs}
      overlayHeaderOverContent={false}
      location={location}
      showFooter={false}
    >
      <Seo
        title={seoTitle || name}
        description={seoDescription || ''}
        clearTemplate={person.seo?.clearTemplate}
        image={seoImage ? { url: seoImage, width: 1200, height: 630 } : null}
        location={location}
      />
      <Grid gridTemplateColumns={grid} data-component="wut">
        <BrowserNotSupported locale={pageContext.locale} />
      </Grid>
    </Layout>
  ) : (
    <TalksProvider show={person.showEsattoTalksBubble}>
      <Layout
        locale={pageContext.locale}
        alternativeLinks={slugs}
        overlayHeaderOverContent={false}
        location={location}
      >
        <Seo
          title={seoTitle || name}
          description={seoDescription || ''}
          clearTemplate={person.seo?.clearTemplate}
          image={seoImage ? { url: seoImage, width: 1200, height: 630 } : null}
          location={location}
        />
        <Grid gridTemplateColumns={grid} data-component="wut" p={2} pb={[4, 5, 6]}>
          <Box gridColumn="2 / -2" px={[3, 2, 0]}>
            <InnerGrid>
              <Box gridColumn={isMobile ? "1 / -1" : "1 / 3"} mb={4} pr={[0, 3, 3]}>
                <GatsbyImage image={{ ...(fluidImage as any), aspectRatio: 16 / 9 }} alt="person image" />
              </Box>

              <Box gridColumn={isMobile ? "1 / -1" : "3 / -1"} pl={[0, 3, 3]}>
                <Box gridColumn={isMobile ? "1 / -1" : "1 / 3"}>
                  <H1 fontWeight={900} fontSize={[50, 60]} mb={0} style={{ wordBreak: 'break-word' }}>{name}</H1>
                </Box>

                <Box gridColumn={isMobile ? "1 / -1" : "-3 / -1"}>
                  <Text fontSize={[4, 5]} mt={0} fontFamily="Handsome">
                    {title}
                  </Text>
                </Box>

                <Box mt={4}>
                  {email && (
                    <Text fontWeight={700} fontSize={[2, 3]}>
                      <A href={`mailto:${email}`} fontSize={1}>
                        {email}
                      </A>
                    </Text>
                  )}
                  {phone && (
                    <Text fontWeight={700} fontSize={[2, 3]}>
                      <A href={`tel:${phone}`} fontSize={1}>
                        {phone}
                      </A>
                    </Text>
                  )}
                </Box>
              </Box>
            </InnerGrid>

            {body && !secondaryBody &&
              <Box maxWidth={800} my={4}>
                <BlockContentRenderer body={body || []} />
              </Box>}
            {body && secondaryBody &&
              <Box style={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row' }}>
                <Box my={4} pr={4} style={{ width: isMobile ? '100%' : '50%' }}>
                  <BlockContentRenderer body={body || []} />
                </Box>
                <Box my={4} pr={4} style={{ width: isMobile ? '100%' : '50%' }}>
                  <BlockContentRenderer body={secondaryBody || []} />
                </Box>
              </Box>
            }
          </Box>
        </Grid>

        <Grid gridTemplateColumns={grid}>
          <SectionRenderer sections={fullSections} />
        </Grid>
      </Layout>
    </TalksProvider>
  );
}

export const query = graphql`
  query PersonQuery($id: String!) {
    person: sanityPeople(_id: { eq: $id }) {
      id
      person {
        firstName
        lastName
        title
        phone
        email
        slug {
          current
        }
        image {
          asset {
            gatsbyImageData(width: 640, height: 360)
          }
        }
        body: _rawBody
        secondaryBody: _rawSecondaryBody
      }

      showEsattoTalksBubble

      seo {
        title {
          sv
          en
          dk
        }
        description {
          sv
          en
          dk
        }
        clearTemplate
        follow
        index
        seoImage: image {
          asset {
            gatsbyImageData(width: 1200, height: 630, layout: FIXED)
          }
        }
      }
  
      _rawSections
      sections {
        ...Sections
      }
    }
  }
`;

export type Section = {
  raw?: any | null | undefined;
} & Sections;

export type PersonSectionRendererProps = {
  sections: Section[];
};

export default Person;